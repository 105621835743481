<!--

# Button

You click them every day. Any time we engage the user for a primary, secondary action
they should be using a <Button />. We also need to scope this component in those terms,
not things like padding, color.

The primary action should look primary across the application. Secondary look secondary.
-->

<template>
  <span class="rounded-md whitespace-nowrap font-attention">
    <button :type="type" :disabled="disabled"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
      :class="[
        classes.all,
        classes.color[color],
        classes.size[size],
        loading ? classes.loading : '',
        disabled ? classes.disabled : '',
        full ? 'size-full justify-center': 'inline-flex',
      ]">
      <slot></slot>
      <Loader v-if="loading" :size="sizeAndMargin.size" :color="loaderColor" :class="sizeAndMargin.margin" />
    </button>
  </span>
</template>

<script>
import Loader from './Loader.vue'
import { useDark } from '@vueuse/core'
import tailwind from '@/tailwind.js'

const isDark = useDark()

export default {
  name: 'Button',

  components: { Loader },

  props: {
    type: {
      type: String,
      validator: (val) => ['button', 'submit', 'reset'].includes(val),
      default: 'button'
    },

    color: {
      type: String,
      validator: (val) => ['red', 'purple', 'white', 'base', 'transparent', 'green','amber','gray','dark-gray'].includes(val),
      default: 'base'
    },

    size: {
      type: String,
      validator: (val) => ['xs', 'sm', 'base', 'xl'].includes(val),
      default: 'base'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    full: {
      type: Boolean,
      default: false
    },

    outline: {
      type: Boolean,
      default: false
    },
  },

  // To account for color of loader when button is hovered over
  data() {
    return {
      isHovered: false
    }
  },

  computed: {
    // Compute the size and margin for the loader according to the button's size
    sizeAndMargin() {
      const sizes = {
        xs: { size: 12, margin: 'ml-1.5' },
        sm: { size: 13, margin: 'ml-2' },
        base: { size: 14, margin: 'ml-2' },
        xl: { size: 16, margin: 'ml-2.5' }
      }
      return sizes[this.size] || sizes.base
    },
    loaderColor() {
      switch (this.color) {
      case 'dark-gray':
        if (!this.outline || (this.outline && (isDark.value || this.isHovered))) {
          return 'white'
        }
        return 'gray'

      case 'base':
        if (isDark.value) {
          // Between 'white' and 'gray' to suit text color
          return tailwind.theme.colors.charcoal[400]
        } else if (this.outline || !this.hovered) {
          return 'gray'
        }
        return 'white'
      
      case 'transparent':
        if (isDark.value) {
          return tailwind.theme.colors.charcoal[400]
        } else if (this.outline || !this.hovered) {
          return 'gray'
        }
        return 'white'

      case 'gray':
        if (isDark.value && this.outline && !this.hovered) {
          return tailwind.theme.colors.charcoal[400]
        }
        return 'gray'

      default:
        if (this.outline && !this.isHovered) {
          return this.color
        }
        return 'white'
      }
    },
    classes() {
      let colorClasses
      if(this.outline) {
        colorClasses = {
          transparent: `
            text-charcoal-700 
            dark:text-charcoal-400
            border 
            border-charcoal-300 
            dark:border-charcoal-700 
            ${!this.disabled ? 'hover:bg-charcoal-100 dark:hover:bg-charcoal-700 active:text-charcoal-800 active:bg-charcoal-200 dark:active:bg-charcoal-800': null }
          `,
          purple: `
            text-primary-link 
            border 
            border-primary-link 
            ${!this.disabled ? 'hover:bg-iris-600 hover:text-white active:bg-iris-800' : null }
          `,
          red: `
            text-red-600 
            border 
            border-red-600 
            ${!this.disabled ? 'hover:bg-red-600 hover:text-white active:bg-red-800' : null }
          `,
          green: `
            text-green-700 
            border 
            border-green-700 
            ${!this.disabled ? 'hover:bg-green-600 hover:text-white active:bg-green-800' : null }
          `,
          base: `
            text-charcoal-700 
            dark:text-charcoal-400
            border 
            border-charcoal-300 
            dark:border-charcoal-800 
            bg-charcoal-100
            dark:bg-charcoal
            ${!this.disabled ? 'hover:bg-charcoal-200 dark:hover:bg-charcoal-700 active:text-charcoal-800 active:bg-charcoal-300 dark:active:bg-charcoal-800': null }
          `,
          amber: `
            text-amber-700 
            border 
            border-amber-700 
            ${!this.disabled ? 'hover:bg-amber-700 hover:text-white active:bg-amber-800': null }
          `,
          gray: `
            text-charcoal-700 
            bg-charcoal-100 
            border 
            border-charcoal-400 
            dark:bg-transparent 
            dark:text-charcoal-300 
            dark:border-charcoal-300 
            ${!this.disabled ? 'hover:bg-charcoal-200 dark:hover:bg-charcoal-500 dark:hover:text-charcoal-200 dark:hover:border-transparent active:bg-charcoal-400 active:text-white dark:active:bg-charcoal-700 dark:active:text-white' : null }
          `,
          'dark-gray': `
            text-charcoal-700 
            dark:text-charcoal-200 
            border 
            border-charcoal-700 
            dark:bg-charcoal-700 
            dark:border-charcoal-200 
            ${!this.disabled ? 'hover:text-white dark:hover:text-white hover:bg-charcoal-600 dark:hover:bg-charcoal-400 dark:hover:border-transparent active:bg-charcoal-800 dark:active:bg-charcoal-800' : null }
          `
        }
      }
      else {
        colorClasses = {
          transparent: `
            text-charcoal-700 
            dark:text-charcoal-300
            dark:hover:bg-charcoal-800
            ${!this.disabled ? 'hover:bg-charcoal-100 dark:hover:bg-charcoal-700 active:text-charcoal-800 active:bg-charcoal-200 dark:active:bg-charcoal-700': null }
          `,
          purple: `
            text-white 
            bg-iris-700 
            focus:border-iris-300 
            ${!this.disabled ? 'hover:bg-iris-600 active:bg-iris-800' : null }
          `,
          red: `
            text-white 
            bg-red-700 
            focus:border-red-300 
            ${!this.disabled ? 'hover:bg-red-600 active:bg-red-800' : null }
          `,
          green: `
            text-white 
            bg-green-700 
            focus:border-green-300 
            ${!this.disabled ? 'hover:bg-green-600 active:bg-green-800' : null }
          `,
          base: `
            text-charcoal-700 
            bg-charcoal-100
            dark:bg-charcoal 
            dark:text-charcoal-400 
            dark:border-charcoal-800 
            dark:hover:bg-charcoal-700 
            ${!this.disabled ? 'hover:bg-charcoal-200 dark:hover:bg-charcoal-700 active:text-charcoal-800 active:bg-charcoal-300 dark:active:bg-charcoal-800': null }
          `,
          amber: `
            text-white 
            bg-amber-700 
            focus:border-amber-300 
            ${!this.disabled ? 'hover:bg-amber-600 active:bg-amber-800' : null }
          `,
          gray: `
            text-charcoal-700 
            bg-charcoal-200 
            border-charcoal-300 
            dark:bg-charcoal-300 
            dark:text-charcoal-800 
            ${!this.disabled ? 'hover:bg-charcoal-100 hover:border-charcoal-400 dark:hover:bg-charcoal-200 active:bg-charcoal-500 active:text-white dark:active:bg-charcoal-600 dark:active:text-white' : null }
          `,
          'dark-gray': `
            text-white 
            bg-charcoal-700 
            focus:bg-charcoal-400 
            ${!this.disabled ? 'hover:bg-charcoal-600 active:bg-charcoal-800' : null }
          `
        }
      }
      return {
        all: 'inline-flex items-center font-medium rounded focus:outline-none focus:ring-iris-500 transition ease-in-out duration-75',
        color: colorClasses,
        loading: 'opacity-50 cursor-not-allowed',
        size: {
          xs: 'px-2 py-1 text-xs',
          sm: 'px-3 py-1 text-sm',
          base: 'px-4 py-2 text-sm',
          xl: 'px-6 py-3 text-base'
        },
        disabled: 'opacity-50 cursor-not-allowed',
      }
    }
  }
}
</script>
